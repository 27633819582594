<template>
  <div>
    <b-row>
      <b-col cols="9">
        <info @readData="showData" />
      </b-col>
      <b-col cols="3">
        <b-card
          v-if="isUser"
          title="Akses Aplikasi"
        >
          <p style="margin-bottom: 5px;">
            Level : <span class="badge mr-1 bg-primary">{{ level }}</span>
          </p>
          <p style="margin-bottom: 5px;">
            Login Terakhir : {{ last_login }}
          </p>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {BRow, BCol, BCard} from 'bootstrap-vue'

import Info from './include/Info.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    Info,
    // Detail
  },
  data(){
    return{
      level:'-',
      last_login:'-',
      isUser:false
    }
  },
  methods:{
    showData(data){
      if(data.user){
        this.isUser = true
        this.level = data.user.role?data.user.role.name:'-'
        this.last_login = data.user.last_login_at==null?'-':data.user.last_login_at
      }
    }
  }
}
</script>
