<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields-title="fieldsTitle"
      :fields-content="fields"
      :baseroute="baseroute"
      @readData="showData"
    >
      <template v-slot:buttonaction>
        <b-button
          v-if="approval_status == 0"
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          @click.prevent="confirmApproval(true)"
        >
          
          <span>Setujui</span>
        </b-button>
        <b-button
          v-if="approval_status == 0"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="confirmApproval(false)"
        >
          
          <span>Tolak</span>
        </b-button>
      </template>
    </base-info>
  </b-card>
</template>

<script>

import {BCard, BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoV2.vue'

export default {
  components: {
    BCard,
    BButton,
    BaseInfo
  },

  data() {
    return {
      dataurl:"/staff",
      baseroute:"staff",
      fieldsTitle: [
        { key: 'name', is_title:true},
        { key: 'postal_code'},
        { key: 'email'},
      ],
      fields: [
        { key: 'address', label: 'Alamat', icon:'CompassIcon'},
        { key: 'phone', label: 'Handphone', icon:'PhoneIcon'},
        { key: 'is_active', label: 'Status', type:'span', icon:'CheckIcon', data:{true:'Aktif', false:'Tidak Aktif'}},
        { key: 'job_title.name', label: 'Jabatan', icon:'StarIcon'},
        { key: 'region.name', label: 'Region', icon:'FlagIcon'},
        { key: 'area.name', label: 'Area', icon:'FlagIcon'},
        { key: 'ulp.name', label: 'U L P', icon:'FlagIcon'},
        { key: 'kantorjaga.name', label: 'Kantor Jaga', icon:'FlagIcon'},
      ],
      approval_status : null,
      model:null
    }
  },
  methods:{
    showData(result){
      this.$emit("readData", result)
      this.model = result
      this.approval_status = result.approval_status
    },
    confirmApproval(approve){
      const id = this.$route.params.id
      let params = {
        approval_status : 2,
        approval_note : 'ditolak'
      }

      if(approve){
        params.approval_status = 1
        params.approval_note = 'diterima'
      }

      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/approval', params)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }

  }
}
</script>